<template>
  <div class="headerTab-container">
    <div
      class="page-outter"
      style="width: 70%;min-width: 1300px;"
    >
      <div class="headerTab-inner">
        <div style="display: flex;align-items: flex-end">
          <img style="height: 32px;width: 46px"
               class="h-i-img"
               :src="require('../../assets/images/1-10.png')"
          />
          <span @click="gohome" style="color: #fff;font-size: 25px;margin-left: 10px;cursor: pointer">海南九为医学科技有限公司</span>
        </div>
        <el-menu
          :default-active="activeIndex"
          background-color="#070c17"
          text-color="#fff"
          active-text-color="#6AA5FF"
          class="el-menu-header"
          mode="horizontal"
          @select="handleSelect"
        >
          <el-menu-item index="1">首页</el-menu-item>
          <!-- <el-menu-item index="2">九医云医院</el-menu-item> -->
          <el-menu-item index="6">药品展示</el-menu-item>
<!--          <el-submenu index="3">-->
<!--            <template slot="title">九医药学院</template>-->
<!--            <el-menu-item index="3-1">智慧医药零售管理平台</el-menu-item>-->
<!--            <el-menu-item index="3-2">智能医药仓储管理平台</el-menu-item>-->
<!--            <el-menu-item index="3-3">智慧药厂大数据管理平台</el-menu-item>-->
<!--            <el-menu-item index="3-4">首营电子资料交换平台</el-menu-item>-->
<!--            <el-menu-item index="3-5">产品溯源平台</el-menu-item>-->
<!--          </el-submenu>-->
          <el-menu-item index="4">联系我们</el-menu-item>
          <el-menu-item index="5">下载APP</el-menu-item>
        </el-menu>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "headerTab",
  props: {
    activeIndex: {
      type: String,
      default: "6",
    },
  },
  data() {
    return {};
  },
  methods: {
    handleSelect(key) {
      let _this = this;
      switch (key) {
        case "1":
          _this.$router.push({ name: "home" });
          break;
        case "2":
          _this.$router.push({ name: "cloudHospital" });
          break;
        case "3-1":
          _this.$router.push({ name: "smartRetail" });
          break;
        case "3-2":
          _this.$router.push({ name: "smartMedicineWarehouse" });
          break;
        case "3-3":
          _this.$router.push({ name: "smartPharmaceuticalFactory" });
          break;
        case "3-4":
          _this.$router.push({ name: "electronicData" });
          break;
        case "3-5":
          _this.$router.push({ name: "productTraceability" });
          break;
        case "4":
          _this.$router.push({ name: "aboutUs" });
          break;
        case "5":
            let a = document.createElement('a')
            a.href = 'https://static.91medicine.net/hn-jwdoctor-app/__UNI__89BB7C0_0818093916.apk';
            a.target = "_blank";
            a.download = '九为云医APP';

            a.click()
          break;
        case "6":
          _this.$router.push({ path: "/drug-info/first" });
          break;
      }
    },
    goLogo() {
      window.open("https://www.91medicine.cn/pharmacy-system/#/login");
    },
    gohome() {
      this.$router.push('/')
    }
  },
};
</script>

<style lang="less" scoped>
.headerTab-container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 62px;
  background: #070c17;
  z-index: 1000;
}
.headerTab-inner {
  width: 100%;
  height: 62px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.h-i-img {
  width: 3.2rem;
}
.el-menu-header {
  background: transparent;
  border-style: none;
  margin-top: 0px;
  color: #fff;
}
</style>